<template>
  <div class="world calculator-page">
    <div class="wrapper-title">
      <div class="container">
        <button class="text-wilmsLightBlue font-100 block" @click="backToOverview">&larr; {{ $t('Terug naar offerte') }}</button>
        <h1 v-if="quoteNumber">{{ $t('Offerte') + ' ' + quoteNumber }}: {{ $t('Configuratie') }} {{ selectedOptions.name === '' || !selectedOptions.name ? $t('zonnescherm') : selectedOptions.name }}</h1>
        <div class="flex"></div>
      </div>
    </div>

    <div class="calculator-wrapper">
      <div class="container">
        <div class="steps-wrapper">
          <div class="step-buttons">
            <h3>{{ $t('Configuratie') }}</h3>
            <CalculatorButton :activeStep="activeStep" :label=" $t('Naam') " :type="'name'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateName())" :label=" $t('Type') " :type="'type'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateType())" :label=" $t('Bediening') " :type="'controls'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateControls())" :label=" $t('Motorisatie') " :type="'motor'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateMotor())" :label=" $t('Breedte en uitval') " :type="'size'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateWidth())" :label=" $t('Kleur kast') " :type="'colors'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateColors())" :label=" $t('Kleur doek') " :type="'fabric'" @setStep="setStep"/>
            <CalculatorButton :activeStep="activeStep" :disabled="!(validateFabric())" :label=" $t('Opties') " :type="'options'" @setStep="setStep"/>
          </div>

          <div class="step-contents">
            <div v-show="activeStep === 'name'" :class="activeStep">
              <h2>{{ $t('Geef een naam aan je zonnescherm') }}</h2>

              <formulate-form>
                <formulate-input ref="name" v-model="selectedOptions.name" :label="$t('Naam')" validation="required"></formulate-input>

                <div class="buttons">
                  <button :disabled="!(validateName())" class="btn" @click="setStep('type')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-show="activeStep === 'type'" :class="activeStep">
              <h2>{{ $t('Naar welk type scherm ben je op zoek?') }}</h2>

              <div class="types types-articulating-screen">
                <div v-for="(type, typeKey) in types" :key="typeKey" :class="{'active': selectedOptions.type === typeKey }" class="type" @click="selectType(typeKey)" @dblclick="setStep('controls')">
                  <div class="image">
                    <img :src="type.image" alt="Type 700lx">
                    <Icon v-if="selectedOptions.type === typeKey" color="#79B0EA" height="30" icon="ic:round-check-circle"/>
                  </div>
                  <h3>{{ type.label }}</h3>
                  <div class="usps">
                    <div v-for="(usp, i) in type.usps" :key="i" class="usp">
                      <Icon color="#79B0EA" icon="ic:round-check-circle" width="15"/>
                      <span v-html="usp"></span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateType())" class="btn" @click="setStep('controls')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-show="activeStep === 'controls'">
              <h2>{{ $t('Welk type bedieningssysteem wenst u?') }}</h2>
              <formulate-form>
                <formulate-input ref="connection" v-model="selectedOptions.connection" :disabled="selectedOptions.type === '700lx'" :label="$t('Bediening')" :options="connections" type="select" validation="required"></formulate-input>

                <div class="buttons">
                  <button :disabled="!(validateControls())" class="btn" @click="setStep('motor')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-show="activeStep === 'motor'">
              <formulate-form>
                <h2>{{ $t('Selecteer het type motor') }}</h2>
                <formulate-input ref="engine" v-model="selectedOptions.engine" :disabled="selectedOptions.type === '700lx'" :label="$t('Motor')" :options="engineOptions" :placeholder="$t('Selecteer type motor')" type="select" validation="required" @change="setEngine"></formulate-input>
                <div class="buttons">
                  <button :disabled="!(validateMotor())" class="btn" @click="setStep('size')">{{ $t('Volgende') }}</button>
                </div>
              </formulate-form>
            </div>

            <div v-show="activeStep === 'size'">
              <h2>{{ $t('Selecteer jouw breedte en uitval') }}</h2>

              <div class="flex gap-10 lg:flex-col">
                <formulate-form ref="widthForm" name="widthForm">
                  <formulate-input
                    ref="width"
                    v-model="selectedOptions.width"
                    :help="$t('De minimale breedte is ') + minWidth + 'mm. ' + $t('De maximale breedte is ') + maxWidth + 'mm.'"
                    :label="$t('Breedte (mm)')"
                    :max="maxWidth"
                    :min="minWidth"
                    :placeholder="$t('Vul een breedte in')"
                    :validation="'required|number|between:' + (minWidth - 1) + ',' + (maxWidth + 1) + ',value'"
                    :validationMessages="{ between: $t('Breedte moet tussen ' + minWidth + ' en ' + maxWidth) }"
                    type="number"
                    @input="setWidth"
                  ></formulate-input>
                  <formulate-input validation="required" v-model="selectedOptions.reach" :help="$t('De mogelijke uitvalbreedtes worden berekend op basis van de opgegeven breedte.')" :label="$t('Uitval (mm)')" :options="reachOptions" :placeholder="$t('Selecteer uitval')" type="select"></formulate-input>
                </formulate-form>
                <img alt="Illustration" src="@/assets/img/calculator/articulating_screen_illustration.svg">
              </div>

              <div class="buttons">
                <button :disabled="!(validateWidth())" class="btn" @click="setStep('colors')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-show="activeStep === 'colors'">
              <h2>{{ $t('Selecteer de kastkleur') }}</h2>
              <h3>{{ $t('Onze meest gekozen kleuren') }}</h3>

              <div class="colors">
                <div v-for="color in defaultColors" :key="color.ral" :class="{ 'active' : selectedOptions.ral === color.ral }" class="color-wrapper" @click="selectColor(color.ral)" @dblclick="setStep('fabric')">
                  <Icon v-if="selectedOptions.ral === parseInt(color.ral)" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.ral === parseInt(color.ral) }" :style="{ 'background-color': ralColors[color.ral].color.hex }" class="color"></div>
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <h3>{{ 'RAL' }}</h3>
              <div class="help">{{ $t('Kies een kleur naar keuze') }}</div>

              <formulate-input v-model="ralSearch" :placeholder="$t('Ral kleur zoeken')" class="color-search" name="search"></formulate-input>

              <div class="colors">
                <div v-for="color in ralColorsSorted" :key="color.ral" class="color-wrapper" @click="selectColor(parseInt(color.code))" @dblclick="setStep('fabric')">
                  <Icon v-if="selectedOptions.ral === parseInt(color.code)" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.ral === parseInt(color.code) }" :style="{ 'background-color': color.color.hex }" class="color"></div>
                  <span>RAL{{ color.code }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateColors())" class="btn" @click="setStep('fabric')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-show="activeStep === 'fabric'">
              <h2>{{ $t('Selecteer de kleur van het doek') }}</h2>

              <div class="colors">
                <div v-for="(color, i) in fabricColors" :key="'fabric-' + i" class="color-wrapper" @click="selectFabricColor(color.name)" @dblclick="setStep('options')">
                  <Icon v-if="selectedOptions.fabric_color === color.name" class="check" color="#79B0EA" icon="ic:round-check-circle" width="22"/>
                  <div :class="{ 'active' : selectedOptions.fabric_color === color.name }" :style="{ 'background-color': '#EEEEEE'}" class="color">
                    <img v-if="color.img" :src="color.img" alt="">
                  </div>
                  <span>{{ color.name }}</span>
                </div>
              </div>

              <div class="buttons">
                <button :disabled="!(validateFabric())" class="btn" @click="setStep('options')">{{ $t('Volgende') }}</button>
              </div>
            </div>

            <div v-show="activeStep === 'options'">
              <h2>{{ $t('Opties') }}</h2>
              <div class="help">{{ $t('Selecteer de opties die je wilt toevoegen') }}</div>
              <v-select ref="tester" v-model="selectedOptions.optionsSelected" :options="options" label="name" multiple @option:selecting="selectOption" @option:deselected="deselectOption"></v-select>

              <div v-if="optionHomemotion" class="option-homemotion">
                <div class="help">{{ $t('Toebehoren Homemotion') }}</div>

                <div>
                  <formulate-input :options="{'io_homemotion_acc_pure_smoove_frame': $t('io_homemotion_acc_pure_smoove_frame'), 'io_homemotion_acc_black_smoove_frame': $t('io_homemotion_acc_black_smoove_frame'), 'io_homemotion_acc_silver_mat_smoove_frame': $t('io_homemotion_acc_silver_mat_smoove_frame'), 'io_homemotion_acc_dubbel_pure_smoove_frame': $t('io_homemotion_acc_dubbel_pure_smoove_frame')}" type="radio" @input="selectSubOption"></formulate-input>
                </div>
              </div>

              <div class="option-inputs">
                <div v-for="option in selectedOptions.options" :key="option.id">
                  <div class="icon" @click="deselectOption(option)">
                    <Icon color="#cf5353" icon="clarity:remove-solid"/>
                  </div>
                  <formulate-input v-model="option.amount" :label="$t(option.name)" class="with-suffix" type="number">
                    <template #suffix>
                      <span class="suffix">{{ option.unit_type === 'piece' ? $t('aantal stuks') : $t('aantal lopende meter') }}</span>
                    </template>
                  </formulate-input>
                </div>
              </div>

              <div class="buttons">
                <button class="btn" @click="backToOverview">{{ $t('Configuratie opslaan en terugkeren naar offerte') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalculatorButton from '@/components/world/CalculatorButton'
import { Icon } from '@iconify/vue2'
import { ApiService } from '@/services/admin/api.service'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { DateTime } from 'luxon'
import calculators from '@/calculators'

export default {
  name: 'Calculator',
  mixins: [
    calculators
  ],
  components: {
    Icon,
    CalculatorButton,
    vSelect
  },
  data () {
    return {
      quoteNumber: null,
      calculatorData: null,
      activeStep: 'name',
      price: null,
      ralSearch: '',
      types: {
        '700lx': {
          image: require('@/assets/img/calculator/700lx.webp'),
          label: this.$t('700LX'),
          usps: [
            this.$t('Strak design met rechte kast en voorprofiel'),
            this.$t('Op maat van jouw terras, in overleg met onze adviseur'),
            this.$t('Kast, armen en voorprofiel in een RAL-kleur naar keuze'),
            this.$t('Wilms SunCollection: keuze uit 24 doeken'),
            this.$t('Koppeling met smart home mogelijk'),
            this.$t('Doek tot 7 meter breed'),
            this.$t('Uitval tot 3,5 meter breed'),
            this.$t('<b>Indirecte en directe dimbare LED-verlichting</b>')
          ]
        },
        '700x': {
          image: require('@/assets/img/calculator/700x.webp'),
          label: this.$t('700X'),
          usps: [
            this.$t('Strak design met rechte kast en voorprofiel'),
            this.$t('Op maat van jouw terras, in overleg met onze adviseur'),
            this.$t('Kast, armen en voorprofiel in een RAL-kleur naar keuze'),
            this.$t('Wilms SunCollection: keuze uit 24 doeken'),
            this.$t('Koppeling met smart home mogelijk'),
            this.$t('Doek tot 7 meter breed'),
            this.$t('Uitval tot 3,5 meter breed')
          ]
        }
      },
      defaultColors: [
        {
          name: this.$t('RAL 9005 gitzwart'),
          ral: 9005
        },
        {
          name: this.$t('RAL 9006 alu kleur'),
          ral: 9006
        },
        {
          name: this.$t('RAL 9007 grijs alu'),
          ral: 9007
        },
        {
          name: this.$t('RAL 9011 grafietzwart'),
          ral: 9011
        },
        {
          name: this.$t('RAL 7015 leigrijs'),
          ral: 7015
        },
        {
          name: this.$t('RAL 7016 antracietgrijs'),
          ral: 7016
        },
        {
          name: this.$t('RAL 7033 cementgrijs'),
          ral: 7033
        },
        {
          name: this.$t('RAL 7039 kwartsgrijs'),
          ral: 7039
        },
        {
          name: this.$t('RAL 7021 zwartgrijs'),
          ral: 7021
        },
        {
          name: this.$t('RAL 8022 zwartbruin'),
          ral: 8022
        }
      ],
      minWidth: null,
      maxWidth: null,
      reachOptions: [],
      engineOptions: [],
      options: [],
      optionHomemotion: false,
      connections: {},
      selectedOptions: {
        name: '',
        type: null,
        ral: null,
        fabric_color: null,
        options: [],
        optionsData: [],
        optionsSelected: [],
        invoiceOptions: []
      },
      datetime: DateTime
    }
  },
  async mounted () {
    await this.setDealer()
    await this.fetchQuoteNumber()
    await this.fetchArticulatingScreen()

    if (this.selectedOptions.type) {
      await this.selectType(this.selectedOptions.type)
    }

    if (this.selectedOptions.engine) {
      await this.setEngine()
    }

    if (this.activeStep === 'name') {
      this.$refs.name.$el.querySelector('input').focus()
    }
  },
  methods: {
    async fetchQuoteNumber () {
      await ApiService.fetchQuoteNumber(this.$route.params.quoteId).then(res => {
        this.quoteNumber = res.data
      })
    },
    async fetchArticulatingScreen () {
      if (this.$route.params.quoteId && this.$route.params.id) {
        await ApiService.fetchArticulatingScreen(this.$route.params.id).then(async res => {
          this.calculatorData = res.data

          if (res.status === 200) {
            await this.normalizeCalculatorData(this.calculatorData)
          }
        })
      }
    },
    async backToOverview () {
      await this.setStep(this.activeStep)
      await this.$router.push({
        name: 'ww.quotes.detail',
        params: { id: this.$route.params.quoteId },
        query: { tab: 'overview' }
      })
    },
    async normalizeCalculatorData (data) {
      this.selectedOptions = { ...this.selectedOptions, ...data }
      this.selectedOptions.ral = parseInt(this.selectedOptions.ral)
      this.selectedOptions.optionsSelected = []

      for (const option of this.selectedOptions.options) {
        const foundOption = this.options.find(optionObj => {
          if (optionObj.internal_name === option.name) {
            return optionObj
          }
        })

        if (foundOption) {
          this.selectedOptions.optionsSelected.push(foundOption)
        }
      }
    },
    /** validatons methods **/
    validateName () {
      if (this.selectedOptions.name && this.selectedOptions.name !== '') {
        return true
      }

      return false
    },
    validateType () {
      if (this.validateName() && this.selectedOptions.type) {
        return true
      }

      return false
    },
    validateControls () {
      if (this.validateType() && this.selectedOptions.connection) {
        return true
      }

      return false
    },
    validateMotor () {
      if (this.validateControls() && this.selectedOptions.engine) {
        return true
      }

      return false
    },
    validateWidth () {
      if (this.validateMotor() && this.selectedOptions.width <= this.maxWidth && this.selectedOptions.width >= this.minWidth && this.reachOptions.some(reach => reach === parseInt(this.selectedOptions.reach))) {
        return true
      }

      return false
    },
    validateColors () {
      if (this.validateWidth() && this.selectedOptions.ral) {
        return true
      }

      return false
    },
    validateFabric () {
      if (this.validateColors() && this.selectedOptions.fabric_color) {
        return true
      }

      return false
    },
    async setStep (step) {
      this.calculatorData = { ...this.calculatorData, ...this.selectedOptions }
      this.calculatorData.width = parseInt(this.calculatorData.width)

      if (this.activeStep === 'options' && this.calculatorData.options.length > 0) {
        for (const option of this.calculatorData.options) {
          await ApiService.patchArticulatingScreenOption(this.calculatorData.id, option.id, option)
        }
      }

      if (this.activeStep === 'motor' && this.validateMotor()) {
        await this.setEngine()
      }

      await ApiService.patchArticulatingScreen(this.calculatorData.id, this.calculatorData).then(async res => {
        await this.normalizeCalculatorData(res.data)
      })

      this.activeStep = step

      if (step === 'name') {
        this.$refs.name.$el.querySelector('input, select').focus()
      }

      if (step === 'controls') {
        this.$refs.connection.$el.querySelector('input, select').focus()
      }

      if (step === 'motor') {
        this.$refs.engine.$el.querySelector('input, select').focus()
      }

      if (step === 'size') {
        this.$refs.width.$el.querySelector('input, select').focus()
      }
    },
    async selectType (type) {
      this.selectedOptions.type = type
      this.selectedOptions.connection = 'wireless'

      if (this.selectedOptions.type === '700lx') {
        this.selectedOptions.engine = 'somfy'
      }

      await this.fetchCalculatorInfo()
      await this.setWidth()
    },
    async setEngine () {
      await ApiService.fetchArticulatingScreenOptions(this.selectedOptions.engine).then(res => {
        this.options = res.data.map(option => {
          option.internal_name = option.name
          option.name = this.$t(option.name)
          return option
        })
      })
    },
    selectColor (color) {
      this.selectedOptions.ral = color
    },
    selectFabricColor (color) {
      this.selectedOptions.fabric_color = color
    },
    async fetchCalculatorInfo () {
      await ApiService.fetchArticulatingScreenInfo(this.selectedOptions.type).then((res) => {
        this.minWidth = res.data.width[0]
        this.maxWidth = res.data.width[1]
        this.engineOptions = res.data.engines
        res.data.connections.forEach(obj => {
          this.connections[obj] = this.$t(obj)
        })
      })
    },
    async setWidth () {
      if (this.validateMotor() && this.selectedOptions.width) {
        await ApiService.fetchArticulatingScreenReach(this.selectedOptions.type, this.selectedOptions.engine, this.selectedOptions.connection, this.selectedOptions.width).then(res => {
          this.reachOptions = res.data ? res.data : []
        })
      }
    },
    /** options methods **/
    async selectOption (option) {
      // await this.setStep(this.activeStep)

      const foundOption = this.selectedOptions.options.find(optionObj => optionObj.internal_name === option.internal_name)

      if (!foundOption) {
        let chainedObj = null
        const obj = {
          option_id: option.id,
          amount: option.internal_name === 'volant_height_200mm' ? (this.selectedOptions.width + 950) / 1000 : 1
        }

        await ApiService.createArticulatingScreenOption(this.calculatorData.id, obj)

        if (option.internal_name === 'io_controltransmitters_1_way_hand_situo_5_io_am') {
          chainedObj = this.findOptionByInternalName('io_sensor_sunis_wirefree_io_outdoor_ii')
        } else if (option.internal_name === 'io_sensor_sunis_wirefree_io_outdoor_ii') {
          chainedObj = this.findOptionByInternalName('io_controltransmitters_1_way_hand_situo_5_io_am')
        } else if (option.internal_name === 'io_sensor_thermis_wirefree_io') {
          chainedObj = this.findOptionByInternalName('io_homemotion_tahoma_switch')
        } else if (option.internal_name === 'io_homemotion_tahoma_switch') {
          chainedObj = this.findOptionByInternalName('io_sensor_thermis_wirefree_io')
        } else if (option.internal_name === 'selve_controltransmitters_i_r_marki_ws_hand') {
          chainedObj = this.findOptionByInternalName('selve_controltransmitters_i_r_marki_ws_solar_wind')
        } else if (option.internal_name === 'selve_controltransmitters_i_r_marki_ws_solar_wind') {
          chainedObj = this.findOptionByInternalName('selve_controltransmitters_i_r_marki_ws_hand')
        } else if (option.internal_name === 'io_controltransmitters_1_way_wall_smoove_io_am_pure_shine') {
          this.optionHomemotion = true
        }

        if (chainedObj) {
          await ApiService.createArticulatingScreenOption(this.calculatorData.id, chainedObj)
        }

        await this.fetchArticulatingScreen()
      }
    },
    async selectSubOption (internalName) {
      const option = this.findOptionByInternalName(internalName)
      await ApiService.createArticulatingScreenOption(this.calculatorData.id, option)
      await this.fetchArticulatingScreen()
      this.optionHomemotion = false
    },
    async deselectOption (option, deleteChained = true) {
      // delete option based on input options
      let quoteOption = option

      if (!Object.keys(option).includes('calculated_total')) {
        // delete option based on select data
        quoteOption = this.selectedOptions.options.find(opt => option.internal_name === opt.name)
      }

      if (quoteOption) {
        await ApiService.deleteArticulatingScreenOption(this.calculatorData.id, quoteOption.id)
        await this.fetchArticulatingScreen()

        if (deleteChained) {
          this.deleteChainedOption(quoteOption)
        }
      }
    },
    findOptionByInternalName (internalName) {
      const foundOption = this.options.find(optionObj => optionObj.internal_name === internalName)

      if (foundOption) {
        const existingOption = this.selectedOptions.options.find(optionObj => optionObj.option_id === foundOption.id)

        if (!existingOption) {
          return {
            option_id: foundOption.id,
            amount: 1
          }
        }
      }

      return null
    },
    deleteChainedOption (option) {
      let chainedObj = null

      if (option.internal_name === 'io_controltransmitters_1_way_hand_situo_5_io_am') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_sensor_sunis_wirefree_io_outdoor_ii')
      } else if (option.internal_name === 'io_sensor_sunis_wirefree_io_outdoor_ii') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_controltransmitters_1_way_hand_situo_5_io_am')
      } else if (option.internal_name === 'io_sensor_thermis_wirefree_io') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_homemotion_tahoma_switch')
      } else if (option.internal_name === 'io_homemotion_tahoma_switch') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_sensor_thermis_wirefree_io')
      } else if (option.internal_name === 'selve_controltransmitters_i_r_marki_ws_hand') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'selve_controltransmitters_i_r_marki_ws_solar_wind')
      } else if (option.internal_name === 'selve_controltransmitters_i_r_marki_ws_solar_wind') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'selve_controltransmitters_i_r_marki_ws_hand')
      } else if (option.internal_name === 'io_controltransmitters_1_way_wall_smoove_io_am_pure_shine') {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_homemotion_acc_pure_smoove_frame')
        if (!chainedObj) {
          chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_homemotion_acc_black_smoove_frame')
        }
        if (!chainedObj) {
          chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_homemotion_acc_silver_mat_smoove_frame')
        }
        if (!chainedObj) {
          chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_homemotion_acc_dubbel_pure_smoove_frame')
        }
      } else if (['io_homemotion_acc_pure_smoove_frame', 'io_homemotion_acc_black_smoove_frame', 'io_homemotion_acc_silver_mat_smoove_frame', 'io_homemotion_acc_dubbel_pure_smoove_frame'].includes(option.internal_name)) {
        chainedObj = this.selectedOptions.options.find(opt => opt.internal_name === 'io_controltransmitters_1_way_wall_smoove_io_am_pure_shine')
      }

      if (chainedObj) {
        this.deselectOption(chainedObj, false)
      }
    }
  },
  computed: {
    ralColorsSorted () {
      if (this.ralSearch === '') {
        return []
      }

      const filteredRals = {}
      Object.keys(this.ralColors).forEach(ral => {
        if (ral.includes(this.ralSearch) || ('RAL' + ral).includes(this.ralSearch)) {
          filteredRals[ral] = this.ralColors[ral]
        }
      })
      return filteredRals
    }
  }
}
</script>
