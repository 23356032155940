<template>
  <button :disabled="disabled" @click="setStep" class="step-button" :class="{ 'active': activeStep === type}">
    <span>{{ label }}</span>
    <Icon icon="ic:baseline-chevron-right" height="26"/>
  </button>
</template>

<script>
import { Icon } from '@iconify/vue2'

export default {
  components: {
    Icon
  },
  props: {
    disabled: {
      type: Boolean
    },
    activeStep: {
      type: String
    },
    type: {
      type: String
    },
    label: {
      type: String
    }
  },
  methods: {
    setStep () {
      this.$emit('setStep', this.type)
    }
  }
}
</script>
