import ral from '@/assets/ral.json'

export default {
  data: function () {
    return {
      // fabric types of screens
      fabricTypes: [
        { name: 'serge_1', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'serge_5', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'serge_10', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'soltis_86', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'soltis_92', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'soltis_92_n', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'serge_lunar', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'lodge_6002', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') }
      ],
      fabricColorsScreens: [
        // { name: 'Wit', img: require('@/assets/img/calculator/screen_fabrics/white.jpg') },
        // { name: 'Bruin', img: require('@/assets/img/calculator/screen_fabrics/') },
        // { name: 'Crèmewit', img: require('@/assets/img/calculator/screen_fabrics/') },
        // { name: 'Antracietgrijs', img: require('@/assets/img/calculator/screen_fabrics/') },
        { name: 'White', img: require('@/assets/img/calculator/screen_fabrics/white.jpg') },
        { name: 'White - Pearl', img: require('@/assets/img/calculator/screen_fabrics/white_pearl.jpg') },
        { name: 'Oyster - Shell', img: require('@/assets/img/calculator/screen_fabrics/oyster_shell.jpg') },
        { name: 'Charcoal Bronze', img: require('@/assets/img/calculator/screen_fabrics/charcoal_bronze.jpg') },
        { name: 'Sandstone', img: require('@/assets/img/calculator/screen_fabrics/sandstone.jpg') },
        { name: 'Bronze', img: require('@/assets/img/calculator/screen_fabrics/bronze.jpg') },
        { name: 'Charcoal', img: require('@/assets/img/calculator/screen_fabrics/charcoal.jpg') },
        { name: 'Pearl - Pearl', img: require('@/assets/img/calculator/screen_fabrics/pearl.jpg') },
        { name: 'Grey - Grey', img: require('@/assets/img/calculator/screen_fabrics/grey.jpg') },
        { name: 'Pure Black', img: require('@/assets/img/calculator/screen_fabrics/pure_black.jpg') },
        { name: 'Grey Charcoal', img: require('@/assets/img/calculator/screen_fabrics/grey_charcoal.jpg') }
      ],
      // fabric colors of articulating screens
      fabricColors: [
        { name: 'Manosque Grey', img: require('@/assets/img/calculator/fabrics/manosque_grey.jpeg') },
        { name: 'Manosque Dark Grey', img: require('@/assets/img/calculator/fabrics/manosque_dark_grey.jpeg') },
        { name: 'Naples Grey', img: require('@/assets/img/calculator/fabrics/naples_grey.jpeg') },
        { name: 'Naples Dark Grey', img: require('@/assets/img/calculator/fabrics/naples_dark_grey.jpeg') },
        { name: 'Color Block Black', img: require('@/assets/img/calculator/fabrics/color_bloc_black.jpeg') },
        { name: 'Pensil Dark Grey', img: require('@/assets/img/calculator/fabrics/pensil_dark_grey.jpeg') },
        { name: 'Grège', img: require('@/assets/img/calculator/fabrics/grege.jpeg') },
        { name: 'Papyrus Tweed', img: require('@/assets/img/calculator/fabrics/papyrus_tweed.jpeg') },
        { name: 'Gris', img: require('@/assets/img/calculator/fabrics/gris.jpeg') },
        { name: 'Chamois Tweed', img: require('@/assets/img/calculator/fabrics/chamois_tweed.jpeg') },
        { name: 'Taupe', img: require('@/assets/img/calculator/fabrics/taupe.jpeg') },
        { name: 'Charcoal Tweed', img: require('@/assets/img/calculator/fabrics/charcoal_tweed.jpeg') },
        { name: 'Pierre', img: require('@/assets/img/calculator/fabrics/pierre.jpeg') },
        { name: 'Gris Tweed', img: require('@/assets/img/calculator/fabrics/gris_tweed.jpeg') },
        { name: 'Argent', img: require('@/assets/img/calculator/fabrics/argent.jpeg') },
        { name: 'Souris Chiné', img: require('@/assets/img/calculator/fabrics/souris_chine.jpeg') },
        { name: 'Platine Piqué', img: require('@/assets/img/calculator/fabrics/platine_pique.jpeg') },
        { name: 'Flanelle Chiné', img: require('@/assets/img/calculator/fabrics/flanelle_chine.jpeg') },
        { name: 'Ardoise', img: require('@/assets/img/calculator/fabrics/ardoise.jpeg') },
        { name: 'Acier Piqué', img: require('@/assets/img/calculator/fabrics/acier_pique.jpeg') },
        { name: 'Carbone', img: require('@/assets/img/calculator/fabrics/carbone.jpeg') },
        { name: 'Macadam', img: require('@/assets/img/calculator/fabrics/macadam.jpeg') },
        { name: 'Noir', img: require('@/assets/img/calculator/fabrics/noir.jpeg') },
        { name: 'Basalte Chiné', img: require('@/assets/img/calculator/fabrics/basalte_chine.jpeg') }
      ],
      countries: {
        be: this.$t('België'),
        nl: this.$t('Nederland'),
        fr: this.$t('Frankrijk'),
        de: this.$t('Duitsland'),
        lu: this.$t('Luxemburg')
      },
      ralColors: ral
    }
  },
  mounted () {

  },
  methods: {
  }
}
